.navbar-top {
    background-color: rgb(58, 25,105);
}

.navbar-top .navbar-brand a {
    color: #fff;
}

.navbar-top .nav-item {
    color: #fff;
    font-size: 20px;
}

.card ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.card {
    cursor: pointer;
    margin: 0 5px !important;
}

.card.my-card {
    background-color: lavender;
}

.card-body {
    padding: 0.5rem;
}

.card.hidden {
    background-color: #eee;
}

.card.hidden * {
    visibility: hidden;
}

.card-title.h5 {
    font-size: 1em;
}

li.marker {
    font-size: 0.85em;
}
